import { useEffect, useState } from "react";
import "./App.css";
import logo from "./logo.png";
import photo1 from "./photos/0001.jpg";
import photo2 from "./photos/0002.jpg";
import photo3 from "./photos/0003.jpg";
import photo4 from "./photos/0004.jpg";
import photo5 from "./photos/0005.jpg";
import photo6 from "./photos/0006.jpg";
import photo7 from "./photos/0007.jpg";
import photo8 from "./photos/0008.jpg";
import photo9 from "./photos/0009.jpg";
import photo10 from "./photos/0010.jpg";
import photo11 from "./photos/0011.jpg";
import photo12 from "./photos/0012.jpg";
import photo13 from "./photos/0013.jpg";
import instagram from "./instagram.svg";

const photos = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
  photo10,
  photo11,
  photo12,
  photo13,
];

export function App() {
  const [activePhoto, setActivePhoto] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [timeout, setPhotoTimeout] = useState<any>();

  useEffect(() => () => clearTimeout(timeout));

  const onLoad = () => {
    const nowLoaded = loaded + 1;
    setLoaded(nowLoaded);
    if (nowLoaded === photos.length) {
      nextPhoto(0)();
    }
  };

  const nextPhoto = (current: number) => () => {
    const next = current + 1 >= photos.length ? 0 : current + 1;
    setActivePhoto(next);
    clearTimeout(timeout);
    setPhotoTimeout(setTimeout(nextPhoto(next), 5000));
  };

  return (
    <>
      <div className="h-full">
        <div className="relative w-full h-full overflow-hidden">
          <div className="absolute flex justify-center w-full h-full mx-auto">
            <img
              src={logo}
              className="z-10 object-contain mx-auto"
              alt="umoja logo"
            />
          </div>
          {photos.map((image, index) => (
            <div
              className="absolute w-full h-full overflow-hidden bg-black bg-center bg-no-repeat bg-cover Background"
              key={`image${index}`}
              style={{
                backgroundImage: `url(${image})`,
                opacity: activePhoto === index ? "1" : "0",
                scale:
                  activePhoto === index - 1 ||
                  activePhoto === index - 2 ||
                  (activePhoto === photos.length - 2 && index === 0)
                    ? "1"
                    : "1.2",
              }}
            ></div>
          ))}
        </div>
        <div className="hidden">
          {photos.map((photo) => (
            <img src={photo} key={photo} alt="" onLoad={onLoad} />
          ))}
        </div>
      </div>
      <div className="h-screen text-center">
        <div className="sticky top-0 p-4 top-1/4 lg:top-1/3">
          <h1 className="p-4 text-lg font-bold">CONTACT US</h1>
          <div className="mb-4">
            <div className="underline">
              <a href="mailto:info@umoja.cz">info@umoja.cz</a>
            </div>
            <div className="underline">
              <a href="tel:+420737418835">+420737418835</a>
            </div>
            <div>
              <a
                href="https://instagram.com/umoja_company"
                target="_blank"
                rel="noreferrer"
              >
                <img className="mx-auto" src={instagram} alt="Instagram" />
              </a>
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">Ing. Regina Voříšková, MBA</div>
            <div>CO-FOUNDER, EXECUTIVE DIRECTOR</div>
            <div className="underline">
              <a href="mailto:regina@umoja.cz">regina@umoja.cz</a>
            </div>
            <div className="underline">
              <a href="tel:+420737418835">+420737418835</a>
            </div>
          </div>
          <div>
            <div className="font-bold">MgA. Eva Kolář Burešová</div>
            <div>CO-FOUNDER, ART DIRECTOR</div>
            <div className="underline">
              <a href="mailto:eva@umoja.cz">eva@umoja.cz</a>
            </div>
            <div className="underline">
              <a href="tel:+420724237448">+420724237448</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
